import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Col, Row, Table } from "antd";
import { message } from "antd/es";
import client from "api/graphql/connect";
import Icon from "assets/icons";
import Container from "component/container";
import {
  ShippingMethod,
  useGetShippingMethodListQuery,
} from "generated/graphql";
import {
  ADD_TWF_SHIPPING_CSV,
} from "graphql/mutations.graphql";
import React, { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import TwfButton from "../../component/button";
import { TwfCard } from "../../component/card";
import { PageTitle } from "../../component/header";
import { MarginBox } from "../../component/margin";
import Calculate from "./components/calculator";
import ShippingMethodUpdate from "./components/method-edit";

interface PageProps {}

const ShippingPage: FC<PageProps> = () => {
  const history = useNavigate();

  const [visible, setVisible] = useState(false);

  const [updateVisible, setUpdateVisible] = useState(false);

  const [method, setMethod] = useState<any>();

  const [_, setFile] = useState<File>();

  const [btnLoading, setLoading] = useState<boolean>(false);

  let mainAdmin = false;

  if (localStorage) {
    if (localStorage.getItem("used-channel") === "__default_channel__") {
      mainAdmin = true;
    }
  }

  const { data: GetShippingMethodListQuery, loading } =
    useGetShippingMethodListQuery({
      fetchPolicy: "network-only",
    });

  let data = GetShippingMethodListQuery?.shippingMethods?.items;
  data = data?.sort((a, b) => a.name.localeCompare(b.name));

  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left",
      sorter: (a: any, b: any) => {
        return a.name.localeCompare(b.name);
      },
    },
    {
      title: "Code",
      key: "code",
      align: "left",
      render: (shippingMethod: ShippingMethod) => {
        return shippingMethod.code;
      },
    },
    {
      title: "Enabled",
      key: "enabled",
      align: "left",
      render: (shippingMethod: ShippingMethod) => {
        return shippingMethod?.customFields?.enabled ? (
          <CheckCircleOutlined style={{ color: "green" }} />
        ) : (
          <CloseCircleOutlined style={{ color: "red" }} />
        );
      },
    },
    {
      title: "",
      key: "",
      align: "center",
      render: (shippingMethod: ShippingMethod) => {
        return (
          <TwfButton
            key={shippingMethod.id}
            disabled={!mainAdmin}
            style={{ border: "none" }}
            onClick={() => {
              setMethod(shippingMethod);
              setUpdateVisible(true);
            }}
          >
            <Icon keyword="edit" color="#3C64B1" />
          </TwfButton>
        );
      },
    },
    {
      title: "",
      key: "",
      align: "center",
    },
  ];

  const inputFile = useRef<HTMLInputElement | null>(null);

  const onButtonClick = () => {
    if (inputFile?.current) {
      inputFile.current.click();
    }
  };

  const viewCSV = () => {
    history("/shipping/datatable");
  };

  const upload = (e: any) => {
    setFile(e.target.files[0]);
    const f: File = e.target.files[0];
    setLoading(true);
    client
      .mutate({
        mutation: ADD_TWF_SHIPPING_CSV,
        variables: {
          document: f,
        },
        refetchQueries: ["GetShippingMethodList"],
      })
      .then((data) => {
        if (data.data) {
          message.success("Shipping CSV successfully uploaded");
          window.location.href = "/shipping";
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error.message);
      });
  };

  const onSBClick = () => {
    setVisible(true);
  };

  const filterNull = (arr: any, index: number, self: any) => {
    return (
      arr.name !== "NULL SHIPPING METHOD" &&
      index ===
        self.findIndex((t: any) => t.name === arr.name && t.code === arr.code)
    );
  };

  return (
    <div>
      <Row gutter={[30, 15]} justify="space-between">
        <Col>
          <PageTitle>Shipping</PageTitle>
        </Col>
        <Col>
          <Row gutter={[20, 20]}>
            {/*
          <TwfButton
            type="primary"
            color-twf="primary"
            size-twf="md"
            type-twf="wide"
            hidden={!mainAdmin}
            onClick={goToShipping}
          >
            {breakpoints.md && <Icon keyword="plus" color="#ffffff" />}
            Create New Shipping
          </TwfButton>
          */}

            <input
              type="file"
              onChange={upload}
              accept=".csv"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
            />

            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                style={{ marginLeft: "2%" }}
                onClick={onSBClick}
              >
                Calculate Shipping
              </TwfButton>
            </Col>

            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                hidden={!mainAdmin}
                style={{ marginLeft: "2%" }}
                onClick={onButtonClick}
                loading={btnLoading}
              >
                Upload Shipping CSV
              </TwfButton>
            </Col>

            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                style={{ marginLeft: "2%" }}
                onClick={viewCSV}
              >
                View Shipping Rates
              </TwfButton>
            </Col>

            <Col>
              <TwfButton
                type="primary"
                color-twf="primary"
                size-twf="md"
                type-twf="wide"
                style={{ marginLeft: "2%" }}
                onClick={() => {
                  history("/pickup");
                }}
              >
                Pickup Options
              </TwfButton>
            </Col>

            {/*
          <TwfButton
            type="primary"
            color-twf="primary"
            size-twf="md"
            type-twf="wide"
            style={{marginLeft:"2%"}}
            onClick={showCSV}
            >
              Download Uploaded CSV
          </TwfButton>
          */}
          </Row>
        </Col>
      </Row>
      <MarginBox mt={32}>
        <Container>
          <TwfCard>
            <MarginBox mt={12} mb={56}>
              <Table
                loading={loading}
                columns={columns}
                style={{ maxWidth: "max-content" }}
                scroll={{ x: "max-content" }}
                dataSource={data?.filter(filterNull)}
                pagination={false}
              />
            </MarginBox>
          </TwfCard>
        </Container>
      </MarginBox>

      <Calculate visible={visible} setVisible={setVisible}></Calculate>

      <ShippingMethodUpdate
        key={Math.floor(Math.random() * 10000)}
        visible={updateVisible}
        setVisible={setUpdateVisible}
        shippingMethod={method}
      />
    </div>
  );
};

export default ShippingPage;
