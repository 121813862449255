export const sources = [
  { label: "Internet", value: 1 },
  { label: "Email Campaign", value: 2 },
  { label: "Friends & Family", value: 3 },
  { label: "Social media", value: 4 },
];

export const orderClassifications = [
  { label: "Consumer", value: 1 },
  { label: "Marketing", value: 2 },
  { label: "Personal Consumption", value: 3 },
  { label: "Trade", value: 4 },
  { label: "Club", value: 5 },
];

export const paymentTypes = [
  { label: "Credit Card", value: 1 },
  { label: "Check", value: 2 },
  { label: "On Account", value: 3 },
];

export const shippingOptions = [
  { label: "GSO - Ground", value: 1 },
  { label: "FedEx Ground", value: 2 },
  { label: "FedEx 2-day", value: 3 },
  { label: "FedEx - Overnight", value: 4 },
  { label: "Temperature Control", value: 5 },
  { label: "Warehouse Pick Up", value: 6 },
  { label: "Do Not Fullfill**", value: 7 },
];

export const creditCardInformations = [
  { label: "**** **** **** 1234 EXP01/23", value: 1 },
  { label: "**** **** **** 1234 EXP01/23", value: 2 },
  { label: "**** **** **** 1234 EXP01/23", value: 3 },
];

//export const pickupLocations = [{ label: "Test Location", value: 1 }];

export const pickupLocations = [
  { label: "Winery Warehouse Pickup ($15.00)", value: 1 },
  { label: "Winery Pickup ($10.00)", value: 2 },
];

export const twfShippingTypes = [
  { label: "FEDEX GROUND", value: "FEDEX GROUND" },
  { label: "FEDEX 2 DAY", value: "FEDEX 2 DAY" },
  { label: "FEDEX OVERNIGHT", value: "FEDEX OVERNIGHT" },
  { label: "FEDEX PRIORITY COLD", value: "FEDEX PRIORITY COLD" },
];

export const complianceStatuses = [
  { label: "Failed", value: 0 },
  { label: "Passed", value: 1 },
];

export const varietals = [
  { label: "Cabernet Sauvignon", value: 1 },
  { label: "Cabernet Franc", value: 2 },
  { label: "Pinot Noir", value: 3 },
  { label: "Zinfandel", value: 4 },
  { label: "Syrah", value: 5 },
  { label: "Red Blend", value: 6 },
  { label: "White Blend", value: 7 },
  { label: "Sauvignon Blanc", value: 8 },
  { label: "Chardonnay", value: 9 },
  { label: "Viognier", value: 10 },
  { label: "Rosé", value: 11 },
  { label: "Blanc de Blanc", value: 12 },
  { label: "Blanc de Noir", value: 13 },
  { label: "Grenche", value: 14 },
  { label: "Sangiovese", value: 15 },
  { label: "Merlot", value: 16 },
];

export const appellations = [
  { label: "Napa Valley", value: 1 },
  { label: "Sonoma Coast", value: 2 },
  { label: "Santa Barbara", value: 3 },
  { label: "Spring Mountain District", value: 4 },
  { label: "Oakville", value: 5 },
  { label: "Russian River Valley", value: 6 },
  { label: "St. Rita Hills", value: 7 },
  { label: "Yountviille District", value: 8 },
  { label: "Sonoma", value: 9 },
  { label: "Mendocino County", value: 10 },
  { label: "Clarksburg", value: 11 },
  { label: "California", value: 12 },
];

export const wineTypes = [
  { label: "Red Wines", value: 1 },
  { label: "White Wine", value: 2 },
  { label: "Red Blend", value: 3 },
  { label: "White Blend", value: 4 },
  { label: "Rosé", value: 5 },
  { label: "Sparkling", value: 6 },
  { label: "Non Vintage", value: 7 },
];

export const labels = [
  { label: "1", value: 1 },
  { label: "Russian River Valley Viognier", value: 2 },
  { label: "Darjean Jones Rosé Clarksburg", value: 3 },
];

export const bottleInCases = [
  { label: "Bottle 1", value: 1 },
  { label: "Bottle 2", value: 2 },
  { label: "Bottle 3", value: 3 },
  { label: "Bottle 4", value: 4 },
];

export const regions = [
  { label: "Region 1", value: 1 },
  { label: "Region 2", value: 2 },
  { label: "Region 3", value: 3 },
  { label: "Region 4", value: 4 },
];

export const acidLevels = [
  { label: "Acid level 1", value: 1 },
  { label: "Acid level 2", value: 2 },
  { label: "Acid level 3", value: 3 },
  { label: "Acid level 4", value: 4 },
];

export const phLevels = [
  { label: "PH level 1", value: 1 },
  { label: "PH level 2", value: 2 },
  { label: "PH level 3", value: 3 },
  { label: "PH level 4", value: 4 },
];

export const sugarLevels = [
  { label: "Sugar Level 1", value: 1 },
  { label: "Sugar Level 2", value: 2 },
  { label: "Sugar Level 3", value: 3 },
  { label: "Sugar Level 4", value: 4 },
];

export const residualSugarLevels = [
  { label: "Residual Sugar Level 1", value: 1 },
  { label: "Residual Sugar Level 2", value: 2 },
  { label: "Residual Sugar Level 3", value: 3 },
  { label: "Residual Sugar Level 4", value: 4 },
];

export const productBadges = [
  { label: "Product Badge 1", value: 1 },
  { label: "Product Badge 2", value: 2 },
  { label: "Product Badge 3", value: 3 },
  { label: "Product Badge 4", value: 4 },
];

export const wineCategories = [
  { label: "Red Wine", value: 1 },
  { label: "White Wine", value: 2 },
  { label: "Sparkling", value: 3 },
  { label: "Rosé", value: 4 },
];

export const bottleSizes = [
  { label: "375 ML", value: "375 ML" },
  { label: "500 ML", value: "500 ML" },
  { label: "750 ML", value: "750 ML" },
  { label: "1.5L", value: "1.5L" },
  { label: "3L", value: "3L" },
  { label: "6L", value: "6L" },
  { label: "9L", value: "9L" },
];

export const bottleSizesNoMl = [
  { label: "375 ML", value: "375" },
  { label: "750 ML", value: "750" },
  { label: "1.5L", value: "1500" },
  { label: "3L", value: "3000" },
  { label: "6L", value: "6000" },
];

const range = (start: number, stop: number, step = 1) => {
  const vValues = [];
  const nums = Array(Math.ceil((stop + 1 - start) / step))
    .fill(start)
    .map((x, y) => x + y * step);
  for (let i = 0; i < nums.length; i++) {
    vValues.push({
      label: String(nums[i]),
      value: String(nums[i]),
    });
  }
  return vValues.sort((a: any, b: any) => b.value - a.value);
};

export const vintageValues = range(2000, +new Date().getFullYear());

export const variantOptions = [
  { label: "Bottle Size", value: "Bottle Size" },
  { label: "Vintage", value: "Vintage" },
];

export const ConditionCodes = {
  MinimumOrderAmount: "minimum_order_amount",
  BuyXGetYFree: "buy_x_get_y_free",
  ContainsProducts: "contains_products",
  CustomerGroup: "customer_group",
  ContainsMinAnyProducts: "min_order_quantity",
  CustomerClubMember: "club_member_condition",
};

// need to make this dynamic - promotionActions - gaius
export const ActionCodes = {
  BuyXGetYFree: "buy_x_get_y_free",
  FreeShipping: "free_shipping",
  FreeOrder: "free_of_cost_order",
  OrderFixedDiscount: "order_fixed_discount_x", //re created
  OrderPercentageDiscount: "order_percentage_discount_x",
  ProductsPercentageDiscount: "products_percentage_discount",
  FixedShippingRateDiscount: "fixed_shipping_discount",
  FreeOfCostDiscount: "free_of_cost",
};

export enum SeasonProcessState {
  NotProcessed = 1,
  Scheduled = 2,
  Processed = 3,
}

export enum ClubCustomerState {
  Active = 1,
  Paused = 2,
  Cancelled = 3,
}

export const EmailCategories = [
  { label: "Sign Up Email Confirmation", value: "sign_up_email" },
  { label: "Forgot Your Password", value: "forgot_your_password" },
  { label: "Order Confirmation", value: "order_confirmation" },
  { label: "Club Join Confirmation", value: "club_join_confirmation" },

  { label: "Order Shipped", value: "order_shipped" },
  { label: "User Note Follow Up", value: "user_note_follow_up" },
  { label: "Wine Club Processed", value: "wine_club_processed" },

  {
    label: "Wine Club Pickup Order Confirmation",
    value: "wine_club_pickup_order_confirmation",
  },
  { label: "Wine Club Order Decline", value: "wine_club_order_decline" },

  {
    label: "User Club Membership Activated",
    value: "activated_club_membership",
  },
  { label: "Club Shipment Ready", value: "club_shipment_ready" },
  { label: "Club Shipment Delivered", value: "club_shipment_delivered" },
];
