import React, { useState, useEffect } from "react";
import { H3 } from "../../../component/header";
import TwfModal from "../../../component/modal";
import ModalTitle from "../../../component/modal/title";
import Icon from "../../../assets/icons";
import { Col, Form, Input, InputNumber, message, Row, Select } from "antd";
import ReactQuill from "react-quill";
import { MarginBox } from "../../../component/margin";
import TwfButton from "../../../component/button";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import SeasonProductVariant from "../components/season-product";
import {
  SeasonProductVariantInput,
  UpdateSeasonInput,
  UpdateSeasonMutationVariables,
  useGetSeasonQuery,
  useGetShippingMethodListQuery,
  useUpdateSeasonMutation,
} from "generated/graphql";

const { Item } = Form;

interface ComponentProps {
  seasonId: string;
  visible: boolean;
  setVisible: (value: boolean) => void;
}

const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
];

const ClubEditSeason: React.FC<ComponentProps> = ({
  seasonId,
  visible,
  setVisible,
}) => {
  const [form] = Form.useForm();
  const breakpoints = useBreakpoint();
  const { data: season } = useGetSeasonQuery({ variables: { id: seasonId } });
  const [updateSeasonMutation, { loading }] = useUpdateSeasonMutation();

  const { data: shippingMethods } = useGetShippingMethodListQuery();

  const removeNull = (shippingM: any)=>{
    return (
      shippingM.name !== "NULL SHIPPING METHOD"
      &&
      shippingM.customFields.enabled === true
    );
  }

  const [teaser, setTeaser] = useState<string | undefined>("");
  const [seasonProductVariants, setSeasonProductVariants] = useState<
    SeasonProductVariantInput[]
  >([{ order: 1 } as SeasonProductVariantInput]);

  useEffect(() => {
    if (season?.season) {
      form.setFieldsValue({
        ...season.season,
        shippingMethodID: season.season.shippingMethod?.id
      });
      setTeaser(season.season.teaser);
      setSeasonProductVariants(
        season.season.seasonProductVariants.map((product) => ({
          productVariantID: product.productVariant.id,
          ...product,
          price: product.price / 100,
        }))
      );
    }
  }, [season, form]);

  const addNewSeasonProductVariant = () => {
    setSeasonProductVariants([
      ...seasonProductVariants,
      {
        order: seasonProductVariants.length + 1,
      } as SeasonProductVariantInput,
    ]);
  };

  const finishHandler = (data: UpdateSeasonInput) => {
    if (!formIsValid()) return;
    updateSeasonMutation({
      variables: prepareUpdateSeasonInput(data),
      refetchQueries: ["getSeasonListForClub"],
    })
      .then(({ data }) => {
        if (data?.updateSeason) {
          message.success("Season updated");
          setVisible(false);
          form.resetFields();
        }
      })
      .catch((error) => message.error(error.message));
  };

  const prepareUpdateSeasonInput = (
    data: UpdateSeasonInput
  ): UpdateSeasonMutationVariables => {
    return {
      input: {
        id: seasonId,
        name: data.name,
        minimumBottle: data.minimumBottle,
        maximumBottle: data.maximumBottle,
        shippingMethodID: data.shippingMethodID,
        teaser: teaser,
        seasonProductVariants: seasonProductVariants?.map(
          (variant: SeasonProductVariantInput): SeasonProductVariantInput => ({
            productVariantID: variant.productVariantID,
            price: variant.price * 100,
            defaultBottle: variant.defaultBottle,
            //minimumBottle: variant.minimumBottle,
            //maximumBottle: variant.maximumBottle,
            minimumBottle: 1,
            maximumBottle: 1000,
            order: variant.order,
          })
        ),
      },
    };
  };

  const formIsValid = () => {
    if (!seasonProductVariants.every((s) => s.productVariantID)) {
      message.warning("Product is not selected");
      return false;
    } else if (!seasonProductVariants.every((s) => s.defaultBottle)) {
      message.warning("Default bottle is not selected");
      return false;
    } else return true;
    /*else if (!seasonProductVariants.every((s) => s.minimumBottle)) {
      message.warning("Minimum bottle is not selected");
      return false;
    } 
    else if (!seasonProductVariants.every((s) => s.maximumBottle)) {
      message.warning("Maximum bottle is not selected");
      return false;
    } */
    
  };

  const updateSeasonProduct = (
    seasonProduct: SeasonProductVariantInput,
    index: number
  ) => {
    const seasonProducts = [...seasonProductVariants];
    seasonProducts[index] = seasonProduct;
    setSeasonProductVariants(seasonProducts);
  };

  const deleteSeasonProduct = (index: number) => {
    setSeasonProductVariants([
      ...seasonProductVariants.slice(0, index),
      ...seasonProductVariants.slice(index + 1),
    ]);
  };

  const getProductName = (key: number) => {
    const seasonProductVariant = season?.season?.seasonProductVariants[key];
    if (seasonProductVariant)
      return `${seasonProductVariant.productVariant.product.name} ${seasonProductVariant.productVariant.name}`;
    else return "";
  };

  const getProductPrice = (key: number) => {
    const seasonProductVariant = season?.season?.seasonProductVariants[key];
    if (seasonProductVariant)
      return seasonProductVariant.productVariant.priceWithTax;
    else return 0;
  };

  const initialValues = { displayOnWeb: false, active: true };

  return (
    <TwfModal
      visible={visible}
      onCancel={() => {
        setVisible(false);
      }}
      closeIcon={<Icon keyword="close" />}
      footer={false}
      width={862}
      centered
    >
      <ModalTitle>
        <H3>Edit {form.getFieldValue('name')  || 'Below Season'}</H3>
      </ModalTitle>
      <Form
        form={form}
        layout="vertical"
        onFinish={finishHandler}
        initialValues={initialValues}
      >
        <Item
          label="Season/ Month"
          name="name"
          rules={[
            {
              required: true,
              message: "Please enter season name!",
            },
          ]}
        >
          <Input />
        </Item>
        <Row gutter={12}>
          <Col span={12}>
            {/* <Item
              label="Min Bottle/ Season"
              name="minimumBottle"
              rules={[
                {
                  required: true,
                  message: "Please enter minimum bottle!",
                },
              ]}
            >
              <InputNumber
                size="large"
                placeholder="1"
                style={{ borderRadius: 12, width: "100%" }}
              />
            </Item> */}
          </Col>
          <Col span={12}>
            {/* <Item
              label="Max Bottle/ Season"
              name="maximumBottle"
              rules={[
                {
                  required: true,
                  message: "Please enter maximum bottle!",
                },
              ]}
            >
              <InputNumber
                size="large"
                placeholder="5"
                style={{ borderRadius: 12, width: "100%" }}
              />
            </Item> */}
          </Col>
        </Row>
        <Item label="Teaser">
          <ReactQuill
            theme="snow"
            value={teaser}
            onChange={setTeaser}
            modules={modules}
            formats={formats}
          />
        </Item>
        <Item
          label="Shipping Method"
          name="shippingMethodID"
          rules={[
            {
              required: true,
              message: "Please select shipping!",
            },
          ]}
        >
          <Select
            placeholder="Select"
            options={shippingMethods?.shippingMethods.items.filter(removeNull).map((method) => ({
              value: method.id,
              label: method.name,
            }))}
            suffixIcon={<Icon keyword="chevron-down" color="#7D8592" />}
          ></Select>
          </Item>
        {seasonProductVariants.map((seasonProduct, key) => (
          <SeasonProductVariant
            productName={getProductName(key)}
            productPrice={getProductPrice(key)}
            seasonProduct={seasonProduct}
            onSeasonProductChange={(seasonProduct) =>
              updateSeasonProduct(seasonProduct, key)
            }
            onSeasonProductRemove={() => deleteSeasonProduct(key)}
            key={key}
          />
        ))}
        <TwfButton
          type="link"
          style={{ color: "#373F41" }}
          onClick={addNewSeasonProductVariant}
        >
          <Icon keyword="plus" color="#A6ACBE" width={16} />
          Add
        </TwfButton>
        <MarginBox mt={32}>
          <Row gutter={breakpoints.md ? [24, 24] : [12, 12]} justify="end">
            <Col>
              <TwfButton
                size-twf="xm"
                color-twf="gray-line"
                type-twf="wide"
                onClick={() => {
                  setVisible(false);
                }}
              >
                Cancel
              </TwfButton>
            </Col>
            <Col>
              <TwfButton
                size-twf="xm"
                type="primary"
                color-twf="primary"
                type-twf="wide"
                htmlType="submit"
                loading={loading}
              >
                Save
              </TwfButton>
            </Col>
          </Row>
        </MarginBox>
      </Form>
    </TwfModal>
  );
};

export default ClubEditSeason;
