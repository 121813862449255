import React, { FC } from "react";
import SuperAdminPage from "./superAdmin";

interface PageProps {}

const BrandsPage: FC<PageProps> = () => {
  return (
    <>
        <SuperAdminPage />
    </>
  )
};

export default BrandsPage;
