import { FunctionComponent, useState, useEffect } from "react";
import { Row, Col, Input, AutoComplete, Tag } from "antd";
import TwfButton from "component/button";
import { OperationCard, OperationProps, VariantOption } from "../conditions";
import { SelectTitle } from "component/header";
import {
  useGetProductVariantLazyQuery,
  useProductSelectorSearchLazyQuery,
} from "generated/graphql";

const ContainsProductsCondition: FunctionComponent<OperationProps> = ({
  code,
  configArgs,
  onConfigArgChange,
  onRemove,
}) => {
  const [productSelectorSearchQuery, { data: productResult }] =
    useProductSelectorSearchLazyQuery();
  const [getProductVariantQuery, { data }] = useGetProductVariantLazyQuery();

  const [productVariantValue, setProductVariantValue] = useState<string>("");
  const [productVariantOptions, setProductVariantOptions] = useState<
    VariantOption[]
  >([]);
  const [selectedProductVariants, setSelectedProductVariants] = useState<
    VariantOption[]
  >([]);

  const minimum = configArgs.find((arg) => arg.name === "minimum")?.value;

  useEffect(() => {
    const productVariantIds = configArgs.find(
      (arg) => arg.name === "productVariantIds"
    )?.value;
    if (productVariantIds) {
      let variantIds: string[] = JSON.parse(productVariantIds);
      variantIds.forEach((id) => {
        getProductVariantQuery({ variables: { id } });
      });
    }
  }, [configArgs, getProductVariantQuery]);

  useEffect(() => {
    if (data?.productVariant) {
      setSelectedProductVariants((variants) => [
        ...variants,
        {
          label: `${data.productVariant?.product.name} ${
            data.productVariant!.name
          }`,
          value: data.productVariant!.id,
        },
      ]);
    }
  }, [data]);

  useEffect(() => {
    const result = productResult?.search.items.map((c) => ({
      label: `${c.productName} ${c.productVariantName}`,
      value: c.productVariantId,
    }));
    setProductVariantOptions(result || []);
  }, [productResult]);

  const productVariantSearchHandler = (value: string) => {
    productSelectorSearch(value);
    setProductVariantValue(value);
  };

  const productSelectorSearch = (term: string) => {
    productSelectorSearchQuery({ variables: { term, take: 10 } });
  };

  const addSelectedVariant = (value: string, item: any) => {
    setProductVariantValue("");
    setNewSelectedItems([...selectedProductVariants, item]);
  };

  const removeSelectedVariant = (id: string) => {
    setNewSelectedItems([
      ...selectedProductVariants.filter(
        (variant: VariantOption) => variant.value !== id
      ),
    ]);
  };

  const setNewSelectedItems = (items: VariantOption[]) => {
    setSelectedProductVariants(items);
    onConfigArgChange(
      {
        name: "productVariantIds",
        value: JSON.stringify(items.map((item) => item.value)),
      },
      code
    );
  };

  return (
    <OperationCard
      title={`Buy at least ${minimum} of the specified products `}
      style={{ marginTop: 16 }}
      actions={[
        <TwfButton key="remove" type="link" onClick={() => onRemove(code)}>
          Remove
        </TwfButton>,
      ]}
    >
      <Row>
        <Col span={24}>
          Minimum:{" "}
          <Input
            type="number"
            value={minimum}
            onChange={(e: any) =>
              onConfigArgChange(
                { name: "minimum", value: e.target.value },
                code
              )
            }
            style={{ width: 160 }}
          />
        </Col>
        <Col span={24} style={{ paddingTop: 16 }}>
          {selectedProductVariants.map((variant: VariantOption) => (
            <Tag
              key={variant.value}
              closable
              onClose={() => removeSelectedVariant(variant.value)}
            >
              {variant.label}
            </Tag>
          ))}
        </Col>
        <Col span={24} style={{ paddingTop: 16 }}>
          <SelectTitle>Product variants</SelectTitle>
          <AutoComplete
            options={productVariantOptions}
            onSelect={addSelectedVariant}
            style={{ minWidth: 200, width: "100%" }}
            onSearch={productVariantSearchHandler}
            placeholder="Search by product name or SKU"
            value={productVariantValue}
          ></AutoComplete>
        </Col>
      </Row>
    </OperationCard>
  );
};

export default ContainsProductsCondition;
