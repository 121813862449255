/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Col,
  Dropdown,
  Grid,
  Menu,
  message,
  Popconfirm,
  Row,
  Table,
} from "antd";
import { TablePaginationConfig } from "antd/es";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import client from "api/graphql/connect";
import Icon from "assets/icons";
import TwfButton from "component/button";
import { H2 } from "component/header";
import { MarginBox } from "component/margin";
import {
  Channel,
  ChannelFragment,
  SortOrder,
  useDeleteAdministratorMutation,
  useDeleteAllChannelEntitiesMutation,
  useDeleteChannelMutation,
  useDeleteRoleMutation,
  useGetActiveChannelLazyQuery,
  useGetAdministratorsLazyQuery,
  useGetChannelsLazyQuery,
  useGetRolesLazyQuery,
  useUpdateChannelMutation,
} from "generated/graphql";
import AdvancedSearch from "pages/orders/components/search";
import React, { FC, useEffect, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateChannel from "./components/create";
import EditChannel from "./components/edit";
import { sortBrands } from "utils/sort-brands";

const { useBreakpoint } = Grid;

interface ChannelFilter {
  code?: { contains: string };
  archived: { eq: boolean };
}

interface TableSort {
  [id: string]: SortOrder;
}

interface TableOptions {
  filter: ChannelFilter;
  skip: number;
  take: number;
  sort?: TableSort;
  pagination?: TablePaginationConfig;
}

type Action =
  | { type: "archived"; value: boolean }
  | { type: "code"; value: string }
  | { type: "tableChange"; skip: number; take: number; sort: TableSort };

interface PageProps {}

const tableInitialState: TableOptions = {
  pagination: { current: 1, pageSize: 10 },
  filter: { archived: { eq: false } },
  skip: 0,
  take: 10,
  sort: {},
};

const SuperAdminPage: FC<PageProps> = () => {
  const history = useNavigate();
  const breakpoints = useBreakpoint();
  const [getChannelsQuery, { data, loading }] = useGetChannelsLazyQuery();

  const [updateChannelMutation] = useUpdateChannelMutation();
  //const [deleteChannelMutation] = useDeleteChannelMutation();
 // const [deleteAdministratorMutation] = useDeleteAdministratorMutation();
  //const [deleteRoleMutation] = useDeleteRoleMutation();
  const [getActiveChannelQuery] = useGetActiveChannelLazyQuery();

  const [deleteAllChannelEntities] = useDeleteAllChannelEntitiesMutation();

  const [selectedChannel, _setSelectedChannel] = useState<ChannelFragment>();
  const [createVisible, setCreateVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [tableOptions, setTableOptions] = useReducer(
    reducer,
    tableInitialState
  );

  // const [getRoles] = useGetRolesLazyQuery({
  //   fetchPolicy: "network-only",
  // });

  // const [getAdministrators] = useGetAdministratorsLazyQuery({
  //   fetchPolicy: "network-only",
  // });

  let isMainChannel = false;

  if (typeof window !== "undefined") {
    if (localStorage.getItem("vendure-token") === "__default_channel__") {
      isMainChannel = true;
    }
  }


  useEffect(() => {
    const newTableOptions = tableOptions;
    delete newTableOptions?.pagination;
    getChannelsQuery({
      variables: {
        options: newTableOptions,
      },
      fetchPolicy: "network-only",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableOptions]);




  const archiveClickHandler = (id: string, status: boolean) => {
    updateChannelMutation({
      variables: { input: { id, customFields: { archived: status } } },
      refetchQueries: ["GetChannels"],
    })
      .then(({ data }) => {
        if (data?.updateChannel.__typename === "Channel")
          message.success(`Store ${!status ? "Unarchive" : "Archived"}`);
      })
      .catch((error) => message.error(error.message));
  };

  const editClickHandler = (channel: Channel) => {
    localStorage.setItem("vendure-token", channel.token);
    client.cache.reset();
    getActiveChannelQuery().then(() => {
      message.success(
        "You're logged into: " +
          channel.customFields!.brandName +
          " in order to edit its details"
      );
      history("/settings");
    });
  };

  const deleteClickHandler = (record: any) => {
    /*let roleid: string;
    let adminids: string[] = [];
    getRoles()
      .then((data) => {
        if (data.data?.roles && data.data?.roles.items) {
          const roles = data.data.roles.items;
          for (let i = 0; i < roles.length; i++) {
            const channelids = roles[i].channels.map((channel) => channel.id);
            if (
              channelids.length === 1 &&
              channelids.indexOf(String(record.id)) !== -1
            ) {
              roleid = roles[i].id;
              break;
            }
          }
        }

        getAdministrators()
          .then((data1) => {
            if (
              data1.data?.administrators &&
              data1.data?.administrators.items
            ) {
              const admins = data1.data.administrators.items;
              for (let i = 0; i < admins.length; i++) {
                const rolesid = admins[i].user.roles.map((role) => role.id);
                if (
                  rolesid.length === 1 &&
                  rolesid.indexOf(String(roleid)) !== -1
                ) {
                  adminids.push(admins[i].id);
                }
              }
            }
          })
          .catch((err1) => {
            setIsModalOpen(false);
            message.error(err1.message);
          });
      })
      .catch((err) => {
        setIsModalOpen(false);
        message.error(err.message);
      });*/
    finallyDelete(record.id);
  };

  const finallyDelete = async (
    channelId: string
  ): Promise<void> => {
    await deleteChannelEntities(channelId)
      .then(() => {
        message.success("Successfully deleted store.");
      })
      .catch((err) => {
        message.error(err.message);
      });
  };

  const deleteChannelEntities = (id: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      deleteAllChannelEntities({
        variables: {
          id,
        },
        refetchQueries: ["GetChannels"],
      })
        .then((data) => {
          if (data.data?.deleteAllChannelEntities) {
            resolve(id);
          }
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  };

  // const deleteChannel = (id: string): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     deleteChannelMutation({
  //       variables: { id },
  //       refetchQueries: ["GetChannels"],
  //     })
  //       .then(({ data }) => {
  //         if (data?.deleteChannel.result === DeletionResult.Deleted)
  //           resolve(id);
  //       })
  //       .catch((error) => reject(error.message));
  //   });
  // };

  // const deleteAdministrator = async (ids: string[]) => {
  //   const deleteAdmin = await Promise.all(
  //     ids.map(async (id) => finalAdminDelete(id))
  //   );
  //   return deleteAdmin;
  // };

  // const finalAdminDelete = (id: string): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     deleteAdministratorMutation({
  //       variables: { id },
  //     })
  //       .then(({ data }) => {
  //         if (data?.deleteAdministrator.result === DeletionResult.Deleted)
  //           resolve(id);
  //       })
  //       .catch((error) => reject(error.message));
  //   });
  // };

  // const deleteRole = (id: string): Promise<string> => {
  //   return new Promise((resolve, reject) => {
  //     deleteRoleMutation({
  //       variables: { id },
  //     })
  //       .then(({ data }) => {
  //         if (data?.deleteRole.result === DeletionResult.Deleted) resolve(id);
  //       })
  //       .catch((error) => reject(error.message));
  //   });
  // };

  function reducer(state: TableOptions, action: Action): TableOptions {
    switch (action.type) {
      case "archived":
        return {
          ...state,
          filter: {
            ...state.filter,
            archived: { eq: action.value },
          },
        };
      case "code":
        return {
          ...state,
          filter: { ...state.filter, code: { contains: action.value } },
        };
      case "tableChange":
        return {
          ...state,
          skip: action.skip,
          take: action.take,
          sort: action.sort,
        };
      default:
        return state;
    }
  }

  const loginClickHandler = (token: string, brandName: string) => {
    localStorage.setItem("vendure-token", token);
    client.cache.reset();
    getActiveChannelQuery().then(() => {
      message.success("Successfully logged into the store: " + brandName);
    });
  };

  const tableChangeHandler = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sort: SorterResult<Channel> | SorterResult<Channel>[]
  ) => {
    setTableOptions({
      type: "tableChange",
      skip: (pagination.pageSize || 10) * ((pagination.current || 1) - 1),
      take: pagination.pageSize || 10,
      sort:
        !Array.isArray(sort) && sort.field
          ? {
              [sort.field as string]:
                sort.order === "ascend" ? SortOrder.Asc : SortOrder.Desc,
            }
          : {},
    });
  };

  const columns: any = [
    {
      title: "Logo",
      // width: 40,
      //dataIndex: ["customFields", "logo"],
      render: (record: ChannelFragment) => {
        return (
          <a
            href={record?.customFields?.mainWebsite}
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={record?.customFields?.logo}
              alt=""
              style={{ height: 30 }}
            />
          </a>
        );
      },
    },
    {
      title: "Brand",
      // width: 200,
      sorter: {},
      dataIndex: "code",
      render: (brand: string) => (
        <>
          {brand === "__default_channel__" ? (
            <strong>{"**Wine Foundry**"}</strong>
          ) : (
            brand
          )}
        </>
      ),
    },
    {
      title: "Membership Since",
      // width: 150,
      dataIndex: "createdAt",
      sorter: {},
      render: (createdAt: any) => new Date(createdAt).toLocaleDateString(),
    },
    {
      title: "Membership Fees",
      // width: 100,
      render: (record: ChannelFragment) => {
        return record.customFields?.membershipLevel ? (
          <span>${`${record.customFields?.membershipLevel}`}</span>
        ) : (
          ""
        );
      },
    },
    {
      title: `Total Active Brands ${data?.channelList.totalItems}`,
      // width: breakpoints.md ? 250 : 50,
      render: (record: ChannelFragment) => {
        return (
          <Row gutter={[12, 12]}>
            <Col>
              <TwfButton
                style={{ borderRadius: 9, height: 36, borderColor: "#999" }}
                onClick={() =>
                  loginClickHandler(
                    record.token,
                    record.customFields!.brandName!
                  )
                }
              >
                Login
              </TwfButton>
            </Col>
            <Col>
              <Dropdown
                placement="bottomRight"
                overlay={
                  <Menu style={{ borderRadius: 12 }}>
                    <Menu.Item
                      key="edit"
                      disabled={!isMainChannel}
                      onClick={() => editClickHandler(record)}
                    >
                      Edit Store
                    </Menu.Item>
                    <Popconfirm
                      title="Are you sure to delete this store?"
                      onConfirm={() => deleteClickHandler(record)}
                      onCancel={() => {}}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Menu.Item
                        key="delete"
                        disabled={
                          record.code === "__default_channel__"
                          ||
                          !isMainChannel
                        }
                      >
                        Delete Store
                      </Menu.Item>
                    </Popconfirm>
                    <Menu.Item
                      key="archive"
                      disabled={!isMainChannel}
                      onClick={() =>
                        archiveClickHandler(
                          record.id,
                          !tableOptions.filter.archived.eq
                        )
                      }
                    >
                      {!tableOptions.filter.archived.eq
                        ? "Archive"
                        : "Unarchive"}{" "}
                      Store
                    </Menu.Item>
                  </Menu>
                }
              >
                <TwfButton size-twf="sm" color-twf="gray-line" >
                  Actions <Icon keyword="chevron-down" color="#7D8592" />
                </TwfButton>
              </Dropdown>
            </Col>
          </Row>
        );
      },
    },
  ];

  // const mapNewChannelFirst = (channel: ChannelFragment) => {
  //   console.log(channel?.customFields?.isOldStore);
  // };


if(!data?.channelList?.items){
  return <>loading...</>
}

  return (
    <div>
      <Row gutter={[30, 20]} justify="space-between" wrap={false}>
        <Col>
          <Row align="middle" gutter={[30, 20]}>
            <Col>
              <H2>Welcome Back Super Admin</H2>
            </Col>
            <Col style={{ width: breakpoints.md ? "max-content" : "100%" }}>
              {/* Todo Navid */}
              <AdvancedSearch
                placeholder="Search by brand"
                setFilter={(value) => setTableOptions({ type: "code", value })}
              />
            </Col>
            {breakpoints.md && (
              <Col>
                <TwfButton
                  size-twf="xl"
                  color-twf="gray-line"
                  onClick={() =>
                    setTableOptions({
                      type: "archived",
                      value: !tableOptions.filter.archived.eq,
                    })
                  }
                >
                  <Icon keyword="archive" color="#979797" width={24} />{" "}
                  {tableOptions.filter.archived.eq ? "Active " : "Archived "}
                  Stores
                </TwfButton>
              </Col>
            )}
          </Row>
        </Col>
        {breakpoints.md && (
          <Col>
            <TwfButton
              color-twf="primary"
              type="primary"
              size-twf="lg"
              type-twf="wide"
              onClick={() => {
                if(isMainChannel){
                  setCreateVisible(true);
                }else{
                  message.error("Please login as Wine Foundry to create new store");
                }
              }}
            >
              <Icon keyword="plus" width={16} color="#fff" />
              Create New Store
            </TwfButton>
          </Col>
        )}
      </Row>

      <MarginBox mt={32}>
        <Table
          loading={loading}
          columns={columns}
          dataSource={sortBrands(data?.channelList.items)}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Brands`,
            pageSize: tableOptions.take,
            current: tableOptions.skip / tableOptions.take + 1,
            total: data?.channelList.totalItems,
            position: ["bottomCenter"],
          }}
          onChange={tableChangeHandler}
          style={{ minHeight: 300, maxWidth: "max-content" }}
          scroll={{ x: "max-content" }}
        />
      </MarginBox>
      <CreateChannel visible={createVisible} setVisible={setCreateVisible} />
      <EditChannel
        visible={editVisible}
        setVisible={setEditVisible}
        channel={selectedChannel}
      />
    </div>
  );
};

export default SuperAdminPage;
