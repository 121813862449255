import React from "react";
import { Col, Row, Table } from "antd";
import { Label, Wrapper } from "./style";
import { Channel } from "generated/graphql";
import { Price } from "utils/price";

interface Props {
  order?: any;
  channel?: Channel;
}

const InvoiceDocument = React.forwardRef<any, Props>(
  ({ order }, ref) => {

    const columns = [
      {
        title: "Product",
        dataIndex: "productVariant",
        render: (variant: any) => variant?.product?.name + " " + variant.name,
      },
      {
        title: "Price",
        dataIndex: "unitPrice",
        render: (unitPrice: number) => `$${(unitPrice / 100).toFixed(2)}`,
      },
      {
        title: "Qty",
        dataIndex: "quantity",
      },
      {
        title: "Total",
        dataIndex: "linePrice",
        render: (linePrice: number) => `$${(linePrice / 100).toFixed(2)}`,
      },
    ];

    return (
      <div style={{ padding: 20 }} ref={ref}>
        <Row gutter={24}>
          <Col span={8}>
            <Wrapper>
              <h3>From: {order?.channel?.customFields?.brandName}</h3>
              <div>{order?.channel?.customFields?.address}</div>
              <div>
                {order?.channel?.customFields?.city},{" "}
                {order?.channel?.customFields?.state},{" "}
                {order?.channel?.customFields?.zip}
              </div>
              <div>{order?.channel?.customFields?.email}</div>
            </Wrapper>
          </Col>
          <Col span={8} offset={8}>
            <table>
              <tbody>
                <tr>
                  <Label>Invoice # :</Label>
                  <td>{order?.code}</td>
                </tr>
                <tr>
                  <Label>Invoice Date :</Label>
                  <td>
                    {order?.createdAt &&
                      new Date(order?.createdAt).toLocaleDateString()}
                  </td>
                </tr>
                <tr>
                  <Label>Due Date :</Label>
                  <td>
                    {order?.createdAt &&
                      new Date(order?.createdAt).toLocaleDateString()}
                  </td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <Col span={8}>
            <Wrapper>
              <h3> To: {order?.billingAddress.fullName}</h3>
              <div>{order?.billingAddress.streetLine1}</div>
              <div>{order?.billingAddress.streetLine2}</div>
              <div>
                {order?.billingAddress.city}, {order?.billingAddress.province},{" "}
                {order?.billingAddress.postalCode}
              </div>
              <div>{order?.billingAddress.email}</div>
            </Wrapper>
          </Col>
        </Row>

        <Row
          style={{ marginTop: 48, display: "flex", flexDirection: "column" }}
        >
          <Table
            dataSource={order?.lines.map((obj: any) => ({
              ...obj,
              key: obj.id,
            }))}
            pagination={false}
            columns={columns}
          ></Table>
        </Row>

        <Row style={{ marginTop: 48 }}>
          <Col span={8} offset={16}>
            <table>
              <tbody>
                <tr>
                  <Label>Sub Total : </Label>
                  <td>${(order?.subTotal / 100).toFixed(2)}</td>
                </tr>
                <tr>
                  <Label>Shipping Rate : </Label>
                  <td>${(order?.shipping / 100).toFixed(2)}</td>
                </tr>
                <tr>
                  <Label>Discount: </Label>
                  <td>
                    <Price
                      priceWithTax={order?.discounts?.reduce(
                        (total: any, discount: any) => total + discount.amount,
                        0
                      )}
                    />
                  </td>
                </tr>
                <tr>
                  <Label>Sales Tax :</Label>
                  <td>
                    <Price
                      priceWithTax={
                        order?.subTotalWithTax - order?.subTotal
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <Label>Grand Total :</Label>
                  <td>${(order?.totalWithTax / 100).toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </Col>
        </Row>

        <Row style={{ marginTop: 48, textAlign: "center" }}>
          {order?.customFields.specialInstructions}
        </Row>
      </div>
    );
  }
);

export default InvoiceDocument;
